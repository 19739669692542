<template>
  <div class="pop">
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="成人"
        name="adult"
      >
        <div class="">
          <!-- 成人行李 -->
          <Luggage :luggages='adtLuggages' />
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="儿童"
        name="child"
        v-if="chdLuggages.length>0"
      >
        <div class="">
          <!-- 儿童行李 -->
          <Luggage :luggages='chdLuggages' />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import Luggage from './Luggage'
export default {
  components: {
    Luggage
  },
  props: {
    cabinUnit: {
      type: Object,
      defualt: {}
    }
  },
  data() {
    return {
      activeName: "adult",
      showInf: false,
      chdLuggages: [],
      adtLuggages:[],
      refundTips: ""
    };
  },
  filters:{
    formatBagType(val){
      return val === 'CARRIAGE' ? '免费非托运行李' : val === 'CHECK_IN' ? '免费托运行李' : ''
    }
  },
  watch: {
    cabinUnit: {
      handler(newUnit, oldUnit) {
        this.loadRules();
      }
    }
  },
  mounted() {
    this.loadRules();
  },
  methods: {
    loadRules() {
      var segment = this.cabinUnit && this.cabinUnit.segmentList[0];
      if (!segment) return;
      // mock
      // segment.adtBaggageGroup.baggageList.map(v=>{v.weight=1})
      //mock end
      segment.adtBaggageGroup && (this.adtLuggages = segment.adtBaggageGroup.baggageList)
      segment.chdBaggageGroup && (this.chdLuggages = segment.chdBaggageGroup.baggageList)
    }
  }
};
</script>
<style scoped>
.pop{
  width: 600px;
  display:flex;
  justify-content: flex-start;
}
.pop .tabs-bar {
  border-bottom: 1px solid #e8e8e8;
  margin: 0 0 16px;
  outline: none;
}
.pop .el-table .cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
}
.pop .el-tabs__item {
  padding: 8px 16px !important;
  /* margin-right: 32px; */
  height: auto;
  line-height: normal;
}
.pop .el-tabs__item:hover {
  color: #f87374;
}
.pop .el-popover {
  padding: 12px 10px !important;
}
.pop .el-tabs__nav-wrap::after {
  height: 1px;
}
.pop .el-tabs__active-bar {
  width: 28px !important;
  background-color: #f87374;
}
.el-tabs__item.is-active {
  color: #f87374;
}
.delete-line {
  text-decoration: line-through;
  color: #ccc;
}
.luggage {
  /* display: flex; */
  width: 100%;
}
.luggage-left,
.luggage-right {
  width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.luggage-right-img {
  width: 203px;
  height: 170px;
}
.luggage-left-img {
  width: 215px;
  height: 216px;
  margin-right: 20px;
}
.luggage-info-t {
  font-weight: bold;
}
.pop-t {
  display: block;
  font-weight: bold;
}
</style>