<template>
    <div style="background-color: #f7f7f7">
        <progress-bar :activeIndex="0" />
        <div class="container">
            <div class="innovate" @click="showNotices" v-if="hasNotice">
                <div style="background: rgb(248, 239,228); height:16px; z-index: 10000;padding-right:12px;">
                    <img src="../../assets/notice.png" style="width:16px;height:16px;" />
                </div>
                <div class="top">
                    <span class="tip">出行提醒：</span>
                    <span class="ctn">点击查看出行提醒</span>
                </div>
                <img src="../../assets/left-arrow.png" class="right-icon" />
            </div>
            <div class="ticket">
                <div class="search-filter" style="background-color: #ffffff">
                    <div class="panel-header" style="width: 800px">
                        <ul class="panel-tabs" style="width: 300px">
                            <li @click="switchTrip('OW')" :class="[activeSwitch === 'OW' ? 'active' : '']">
                                <a href="javascript:void(0)">单程</a>
                            </li>
                            <li @click="switchTrip('RT')" :class="[activeSwitch === 'RT' ? 'active' : '']">
                                <a href="javascript:void(0)">往返</a>
                            </li>
                        </ul>
                    </div>
                    <div class="switch-panel" style="clear: both">
                        <div class="filter">
                            <div class="filter-input">
                                <el-autocomplete
                                    class="inline-input"
                                    style="margin-top: 10px"
                                    height="40px"
                                    v-model="searchfilter.deptAirport"
                                    clearable
                                    :fetch-suggestions="querySearch"
                                    placeholder="出发城市"
                                    @select="handleSelect"
                                    @blur="handleSearchOnblur(searchfilter, $refs.deptAirportPannel, 'dept', $event)"
                                >
                                    <template slot-scope="{ item }">
                                        <div class="name">{{ item.cityName }}</div>
                                    </template>
                                </el-autocomplete>
                                <AirportPannel ref="deptAirportPannel" :show="fromFilterShow" @selected="handleSelect"></AirportPannel>
                            </div>
                        </div>
                        <i class="el-icon-sort separet" style="margin-right: 15px" @click="changeFlightsAirports"></i>
                        <div class="filter">
                            <div class="filter-input">
                                <el-autocomplete
                                    class="inline-input"
                                    style="margin-top: 10px"
                                    v-model="searchfilter.arrAirport"
                                    clearable
                                    :fetch-suggestions="querySearch2"
                                    placeholder="到达城市"
                                    @blur="handleSearchOnblur(searchfilter, $refs.arrAirportPannel, 'arr', $event)"
                                    @select="handleSelect2"
                                >
                                    <template slot-scope="{ item }">
                                        <div class="name">{{ item.cityName }}</div>
                                    </template>
                                </el-autocomplete>
                                <AirportPannel ref="arrAirportPannel" @selected="handleSelect2"></AirportPannel>
                            </div>
                        </div>
                        <div class="filter">
                            <el-date-picker
                                v-model="searchfilter.deptDate"
                                placeholder="请选择去程日期"
                                value-format="yyyy-MM-dd"
                                @focus="handleDateFocus"
                                :picker-options="pickerOptions0"
                            ></el-date-picker>
                        </div>
                        <div class="filter" v-show="activeSwitch === 'RT'">
                            <el-date-picker ref="retDatePicker" v-model="searchfilter.retDate" placeholder="请选择返程日期" @focus="handleDateFocus" :picker-options="pickerOptions0"></el-date-picker>
                        </div>
                        <el-button
                            type="danger"
                            class="fliter-btn"
                            style="width: 120px;height: 40px;margin-top: 12px;margin-left: 32px;border-radius: 10px;position: absolute;right: 20px;"
                            @click="searchFlight()"
                        >
                            查&nbsp;询
                        </el-button>
                    </div>
                </div>
                <div v-show="initVisible">
                    <div class="recommend container-module">
                        <el-row :gutter="20">
                            <el-col style="width: 100%">
                                <div style="background-color: #ffffff">
                                    <div class="main-title">推荐航线</div>
                                    <div class="sub-title">为您提供实惠的价格</div>
                                    <div class="title" style="padding-top: 10px; padding-bottom: 5px">
                                        <ul class="discount-city">
                                            <li
                                                @click="changeHot(item)"
                                                :class="item.airportCode == activeHotAirport.airportCode ? 'active' : ''"
                                                v-for="item in hotAirports"
                                                v-bind:key="item.airportCode"
                                            >
                                                {{ item.airportName }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="commend list" style="height: 240px; clear: both">
                                        <el-row :gutter="20">
                                            <el-col :span="6" style="display: none">
                                                <div style="margin-top: 12px">
                                                    <img src="../../assets/ad_csx.png" width="320px; height:220px" />
                                                </div>
                                            </el-col>
                                            <el-col :span="24">
                                                <div class="commendlist">
                                                    <ul>
                                                        <li v-for="(item, index) in lowestFlightsData.slice(0, 4)" v-bind:key="index">
                                                            <a href="javascript:void(0)" @click="gotoHotline(item)" type="SEARCH">
                                                                <dl>
                                                                    <dd>
                                                                        {{ item.depAirportName }}
                                                                        <span></span>
                                                                    </dd>
                                                                    <dd class="center">
                                                                        <img src="https://static.a6air.com/pc/common/fly-to.png" width="25px" height="25px" />
                                                                    </dd>
                                                                </dl>
                                                                <dl>
                                                                    <dd class="to-address">
                                                                        {{ item.arrAirportName }}
                                                                        <span></span>
                                                                    </dd>
                                                                </dl>
                                                                <dl>
                                                                    <dt style="line-height: 27px">
                                                                        {{ item.flightDate }}
                                                                    </dt>
                                                                </dl>
                                                                <em>
                                                                    <div class="e1">￥</div>
                                                                    <div class="e2">
                                                                        {{ item.amount | mathInt }}
                                                                    </div>
                                                                    <div class="e3">起</div>
                                                                </em>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
                <div v-show="!initVisible">
                    <div style="margin-top: 30px" v-if="selectFlight.singleFlight != null && selectFlight.goFlight != undefined">
                        <div class="flight-main" style="position: relative">
                            <div class="flight-main-item" style="width: 50px">
                                <div class="choose-cabin go-flight" style="width: 28px">
                                    <img src="../../assets/fligth_leftlabel.png" alt />
                                </div>
                            </div>
                            <div class="flight-main-item">
                                <p style="margin-top: 13px; font-size: 28px">
                                    {{ selectFlight.singleFlight.depTime }}
                                </p>
                                <p>{{ selectFlight.singleFlight.depAirportName }}机场{{ selectFlight.singleFlight.depAirportTerm }}</p>
                                <p></p>
                            </div>
                            <div class="flight-main-item during">
                                <p style="color: #aaaaaa">
                                    {{ selectFlight.singleFlight.stopQuantity > 0 ? "经停 " + selectFlight.singleFlight.stopAirportName : "直飞" }}
                                </p>
                                <p>
                                    <span class="during-line" style="background: #e4e4e4"></span>
                                    <img class="during-air" src="../../assets/ticket-air.png" alt />
                                </p>
                                <p style="color: #aaaaaa">
                                    {{ selectFlight.singleFlight.duration | formatDateDuring }}
                                </p>
                            </div>
                            <div class="flight-main-item">
                                <p style="margin-top: 13px; font-size: 28px">
                                    {{ selectFlight.singleFlight.arrTime }}
                                </p>
                                <p>{{ selectFlight.singleFlight.arrAirportName }}机场{{ selectFlight.singleFlight.arrAirportTerm }}</p>
                                <p></p>
                            </div>
                            <div class="flight-main-item dinner-item">
                                <p></p>
                                <p style="margin-top: 13px; font-size: 24px; width: 260px">
                                    <img style="width: 26px; height: 14px" src="../../assets/logo-small.png" alt />
                                    <span style="font-size: 14px">
                                        {{ selectFlight.singleFlight.flightNoGroup }}
                                    </span>
                                    <span style="font-size: 14px; margin-left: 10px">空客A{{ selectFlight.singleFlight.aircraftStyle }}</span>
                                    <span style="font-size: 14px; margin-left: 10px">
                                        {{ selectFlight.singleFlight.depDatetime | formatYYMM }}
                                    </span>
                                    <!-- 有无餐食 -->
                                    <span style="font-size: 14px; margin-left: 10px">
                                        {{ selectFlight.singleFlight.flightUnitVOList && selectFlight.singleFlight.flightUnitVOList[0].segmentList[0].meal ? "有餐食" : "无餐食" }}
                                    </span>
                                </p>
                                <p></p>
                            </div>
                            <div class="flight-main-item" style="width: 120px"></div>
                            <div class="flight-main-item" style="width: 190px">
                                <p class="choose-cabin" style="border-left: none;margin-top: 22px !important;font-size: 20px;height: 35px !important;">
                                    {{ selectFlight.goFlight.productSpace.name }}
                                </p>
                                <p></p>
                            </div>

                            <div class="flight-main-item">
                                <p class="choose-cabin" style="border-left: none;margin-top: 20px !important;color: #d44040;height: 80px !important;">
                                    ¥
                                    <span style="font-size: 30px">
                                        {{ selectFlight.goFlight.adultTotalPrice.amount }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="calender-price" style="border: 1px solid #f3f0f0;margin-top: 30px;border-radius: 10px 10px 0 0;background-color: #ffffff;">
                        <div class="calender-swiper">
                            <div class="prev" @click="goPrev" style="border-left: none">
                                <img style="width: 32px; height: 32px" src="../../assets/left.png" alt />
                            </div>
                            <div class="swiper-container-lowprice">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="(item, index) in dateArr" :key="index">
                                        <div :class="priceClass[index].classType" @click="itemClick(index, true)">
                                            <div class="date-info">{{ item.date }}</div>
                                            <div class="price-info" v-if="item.price == '--'">
                                                <span>{{ item.price }}</span>
                                            </div>
                                            <div class="price-info" v-else>
                                                <span>{{ item.price }}</span>
                                                起
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="next" @click="goNext" style="border-right: none; width: 61px">
                                <img style="width: 32px; height: 32px; transform: rotate(-180deg)" src="../../assets/left.png" alt />
                            </div>
                        </div>
                    </div>
                    <div class="ticket-flight" style="min-height: 300px">
                        <ul class="ticket-flight-list" v-loading="searchLoadingStatus">
                            <li class="flight-item" v-if="flights.length <= 0" style="text-align: center">
                                <div class="no-data">
                                    <img src="../../assets/no-data-ticket.png" alt />
                                    <div>
                                        <span>暂无数据，敬请期待</span>
                                    </div>
                                </div>
                            </li>
                            <li
                                class="flight-item"
                                v-for="(flightItem, flightIndex) in flights"
                                v-bind:key="flightItem._id"
                                :style="{
                                    'min-height': '100px',
                                    'margin-top': flightIndex > 0 ? '14px' : '0px'
                                }"
                            >
                                <div class="flight-main">
                                    <div class="flight-main-item">
                                        <p style="margin-top: 13px; font-size: 28px">
                                            {{ flightItem.depTime }}
                                        </p>
                                        <p>{{ flightItem.depAirportName }}机场{{ flightItem.depAirportTerm }}</p>
                                    </div>
                                    <div class="flight-main-item during">
                                        <p style="color: #aaaaaa; margin-top: 5px">
                                            {{ flightItem.stopQuantity > 0 ? "经停 " + flightItem.stopAirportName : "直飞" }}
                                        </p>
                                        <p>
                                            <span class="during-line" style="background: #e4e4e4"></span>
                                            <img class="during-air" src="../../assets/ticket-air.png" alt />
                                        </p>
                                        <p style="color: #aaaaaa; margin-top: 6px">
                                            {{ flightItem.duration | formatDateDuring }}
                                        </p>
                                    </div>
                                    <div class="flight-main-item">
                                        <p style="margin-top: 13px; font-size: 28px">
                                            {{ flightItem.arrTime }}
                                            <span style="font-size: 12px; font-weight: normal" v-if="flightItem.depDatetime != flightItem.arrDatetime">+1</span>
                                        </p>
                                        <p>{{ flightItem.arrAirportName }}机场{{ flightItem.arrAirportTerm }}</p>
                                    </div>
                                    <div class="flight-main-item dinner-item" style="width: 300px">
                                        <p></p>
                                        <p style="margin-top: 0px; font-size: 24px">
                                            <img style="width: 26px; height: 14px" src="../../assets/logo-small.png" alt />
                                            <span style="font-size: 14px">
                                                {{ flightItem.flightNoGroup }}
                                            </span>
                                            <span style="font-size: 14px; margin-left: 10px">空客A{{ flightItem.aircraftStyle }}</span>
                                            <!-- 有无餐食 -->
                                            <span style="font-size: 14px; margin-left: 10px">
                                                {{ flightItem.flightUnitVOList && flightItem.flightUnitVOList[0].segmentList[0].meal ? "有餐食" : "无餐食" }}
                                            </span>
                                        </p>
                                        <p></p>
                                    </div>
                                    <div class="flight-main-item" style="width: 120px"></div>
                                    <div class="flight-main-item" style="width: 110px" v-if="flightItem.ALL[0].length <= 0"></div>
                                    <div class="flight-main-item" style="width: 110px" v-if="flightItem.ALL[1].length <= 0"></div>
                                    <div class="flight-main-item" style="width: 110px" v-if="flightItem.ALL[2].length <= 0"></div>
                                    <div class="flight-main-item" style="width: 110px" v-if="flightItem.ALL[0].length > 0">
                                        <div
                                            class="choose-cabin"
                                            :class="[
                                                flightItem.showFlightUnit && flightItem.currentCabin.cabinGroupName == flightItem.ALL[0][0].cabinGroupName ? 'active-cabin' : '',
                                                flightItem.currentCabin.cabinGroupName == flightItem.ALL[0][0].cabinGroupName &&
                                                (flightItem.ALL[0][0].segmentList[0].cabin.cabinClass == 'F' || flightItem.ALL[0][0].segmentList[0].cabin.cabinClass == 'C')
                                                    ? 'first-cabin'
                                                    : ''
                                            ]"
                                            @click="chooseCurrentCabin(1, flightItem, flightItem.ALL[0][0])"
                                            v-if="flightItem.ALL[0][0] != null"
                                        >
                                            <div>{{ flightItem.ALL[0][0].cabinGroupName }}</div>
                                            ¥
                                            <span style="font-size: 30px">
                                                {{ flightItem.ALL[0][0].adultTotalPrice.amount }}
                                            </span>
                                            起
                                        </div>
                                    </div>
                                    <div class="flight-main-item" style="width: 110px" v-if="flightItem.ALL[1].length > 0">
                                        <div
                                            class="choose-cabin"
                                            :class="[
                                                flightItem.showFlightUnit && flightItem.currentCabin.cabinCodeGroup == flightItem.ALL[1][0].cabinCodeGroup ? 'active-cabin' : '',
                                                flightItem.currentCabin.cabinCodeGroup == flightItem.ALL[1][0].cabinCodeGroup &&
                                                (flightItem.ALL[1][0].segmentList[0].cabin.cabinClass == 'F' || flightItem.ALL[1][0].segmentList[0].cabin.cabinClass == 'C')
                                                    ? 'first-cabin'
                                                    : ''
                                            ]"
                                            @click="chooseCurrentCabin(2, flightItem, flightItem.ALL[1][0])"
                                            v-if="flightItem.ALL[1][0] != null"
                                        >
                                            <div>{{ flightItem.ALL[1][0].cabinGroupName }}</div>
                                            ¥
                                            <span style="font-size: 28px">
                                                {{ flightItem.ALL[1][0].adultTotalPrice.amount }}
                                            </span>
                                            起
                                        </div>
                                    </div>

                                    <div class="flight-main-item" style="width: 110px" v-if="flightItem.ALL[2].length > 0">
                                        <div
                                            class="choose-cabin"
                                            :class="[
                                                flightItem.showFlightUnit && flightItem.currentCabin.cabinGroupName == flightItem.ALL[2][0].cabinGroupName ? 'active-cabin' : '',
                                                flightItem.currentCabin.cabinGroupName == flightItem.ALL[2][0].cabinGroupName &&
                                                (flightItem.ALL[2][0].segmentList[0].cabin.cabinClass == 'F' || flightItem.ALL[2][0].segmentList[0].cabin.cabinClass == 'C')
                                                    ? 'first-cabin'
                                                    : ''
                                            ]"
                                            @click="chooseCurrentCabin(4, flightItem, flightItem.ALL[2][0])"
                                            v-if="flightItem.ALL[2][0] != null"
                                        >
                                            <div>{{ flightItem.ALL[2][0].cabinGroupName }}</div>
                                            ¥
                                            <span style="font-size: 30px">
                                                {{ flightItem.ALL[2][0].adultTotalPrice.amount }}
                                            </span>
                                            起
                                        </div>
                                    </div>
                                </div>
                                <div class="cabin-group" style="padding-top: 10px">
                                    <div v-for="(product, index) in flightItem.flightUnitVOList" v-bind:key="index">
                                        <div v-if="flightItem.currentCabin.cabinGroupName == product.cabinGroupName && flightItem.showFlightUnit" style="position: relative">
                                            <div style="position: absolute; top: 20px" v-if="product.productSpace.productLabelVOList.length > 0">
                                                <el-popover placement="right-start" title width="200" trigger="hover" :content="product.productSpace.productLabelVOList[0].instruction">
                                                    <el-button class="pop-btn" slot="reference" style="line-height: 15px;">
                                                        {{ product.productSpace.productLabelVOList[0].name }}
                                                    </el-button>
                                                </el-popover>
                                            </div>
                                            <ul class="cabin-type">
                                                <li class="cabin-name" style="justify-content: start; padding-left: 20px">{{ product.productSpace.name }}({{ product.cabinCodeGroup }}舱)</li>
                                                <li class="cabin-rule" style="width: 40%">
                                                    <el-popover class="popover" ref="pop" style="margin-right: 0px" trigger="hover" placement="bottom-start">
                                                        <popover-content :cabinUnit="product" :isLuggage="false" />
                                                        <div slot="reference">退改签规则</div>
                                                    </el-popover>
                                                    <span v-if="product.segmentList[0].adtBaggageGroup" style="margin: 0 5px 0 5px; display: block">|</span>
                                                    <el-popover
                                                        class="popover"
                                                        ref="pop"
                                                        style="margin-right: 0px"
                                                        trigger="hover"
                                                        placement="bottom-start"
                                                        v-if="product.segmentList[0].adtBaggageGroup"
                                                    >
                                                        <LuggagePopOver style="margin-left:20px;" :cabinUnit="product" :segmentList="product.segmentList[0]" />
                                                        <div slot="reference">免费托运行李{{ product.segmentList[0].adtBaggageGroup.baggageList | formatWeight }}kg</div>
                                                    </el-popover>
                                                    <span style="margin: 0 5px 0 5px; display: block">|</span>
                                                    <span>赠送{{ product.adultTotalPrice.amount }}积分</span>
                                                </li>
                                                <li class="cabin-discount" style="width: 20%"></li>
                                                <li class="cabin-discount" style="justify-content: center !important; width: 12%">
                                                    <span></span>
                                                    <span></span>
                                                    <span style="font-size: 24px">{{ product.segmentList[0].cabin.discount }}折</span>
                                                </li>
                                                <li class="cabin-price" style="justify-content: center !important;widows: 12%;">
                                                    <div>
                                                        ¥
                                                        <span style="font-size: 30px">
                                                            {{ product.adultTotalPrice.amount }}
                                                        </span>
                                                    </div>
                                                </li>
                                                <li class="cabin-action" style="width: 14%">
                                                    <!-- 去程预订 -->
                                                    <a href="javascript:void(0)" @click="selectGoFlight(flightItem, product)" v-if="activeSearchJouryType === 'RT' && activeJouryType === 'GO'">
                                                        <span>选为去程</span>
                                                        <span>
                                                            {{ product.segmentList[0].cabin.inventory === "A" ? "充足" : "余" + product.segmentList[0].cabin.inventory + "座" }}
                                                        </span>
                                                    </a>
                                                    <!-- 回程预订selectOWFlight -->
                                                    <a href="javascript:void(0)" @click="selectBackFlight(flightItem, product)" v-if="activeJouryType === 'BACK' && activeSearchJouryType === 'RT'">
                                                        <span>选为回程</span>
                                                        <span>
                                                            {{ product.segmentList[0].cabin.inventory === "A" ? "充足" : "余" + product.segmentList[0].cabin.inventory + "座" }}
                                                        </span>
                                                    </a>
                                                    <!-- 单程预订 -->
                                                    <a href="javascript:void(0)" @click="selectOWFlight(flightItem, product)" v-if="activeSearchJouryType === 'OW'">
                                                        <span>预订</span>
                                                        <span>
                                                            {{ product.segmentList[0].cabin.inventory === "A" ? "充足" : "余" + product.segmentList[0].cabin.inventory + "座" }}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import { SELECT_FLIGHT, CURRENT_USER, SEARCH_FILTER, LOGIN_REDIRECT_ROUTE } from "@/store/mutation-types";

const Swiper = window.Swiper;
import moment from "moment";
import { getFlightList, getLowestSchedule, fetchHotAirports, fetchAirportsPannelData, getHotAirlines, getLowestFlights, synServerDate, queryDict } from "@/api/newsapi";
import AirportPannel from "../../components/AirportPannel";
import ProgressBar from "../../components/ProgressBar";
import PopoverContent from "../../components/PopoverContent";
import LuggagePopOver from "../../components/LuggagePopOver";
// 设置中文
moment.locale("zh-cn");
import { EventBus } from "@/api/event-bus.js";
import { resolve } from "url";
import { rejects } from "assert";
export default {
    components: {
        ProgressBar,
        PopoverContent,
        LuggagePopOver,
        AirportPannel
    },
    name: "ticket",
    data() {
        return {
            hasNotice: false,
            noResultNotice: '请输入您的行程信息，点击"立即查询"进行航班信息搜索',
            searchLoadingStatus: false,
            today: null,
            tripDesc: "",
            serverDate: "",
            defaultAirport: {},
            // 往返/单程 切换
            activeSwitch: "OW",
            activeSearchJouryType: "OW",
            activeJouryType: "GO",
            activeSearchRoute: "",
            // 控制出发地选择面板显示
            fromFilterShow: false,
            // 控制目的地选择面板显示
            toFilterShow: false,
            // 测试日期数据
            date: "",
            // end
            // 日期滚动swiper实例
            priceCalender: null,
            priceClass: [],
            // 舱类型
            cabinType: 4,
            restaurants: [],
            flights: [],
            airportPannelData: [],
            showFlightUnit: true,
            airportPannelCurrentLabel: {
                hotAirports: [],
                airports: [],
                commonAirports: []
            },
            selectFlight: {
                jouryType: "OW",
                owFlight: {},
                goFlight: {},
                backFlight: {}
            },
            searchfilter: {
                deptAirport: "长沙",
                deptAirportCode: "CSX",
                arrAirport: "昆明",
                arrAirportCode: "KMG",
                deptDate: "",
                retDate: "",
                passADT: "",
                passCHD: "",
                passINF: ""
            },
            // 滚动日期数据
            dateArr: [],
            pickerOptions0: {
                disabledDate(date) {
                    let zero = new Date().setHours(0, 0, 0, 0);
                    if (date.getTime() < zero) {
                        return true;
                    }
                    return false;
                }
            },
            initVisible: true,
            activeHotAirport: { airportName: "长沙", airportCode: "CSX" },
            hotAirports: [],
            hotAirlines: {},
            lowestFlightsData: [],
            lampX: 0, //跑马灯文字位置
            innovate: ["疫情防控政策：", "疫情防控政策：", "疫情防控政策："] //跑马灯文案
        };
    },
    filters: {
        formatDateDuring(mss) {
            var hoursRound = Math.floor(mss / 60);
            var minutes = parseInt(mss % 60);
            return hoursRound + "h" + minutes + "m";
        },
        formatYYMM(date) {
            return moment(date).format("MM月DD日");
        },
        mathInt(amount) {
            return Math.floor(amount);
        },
        formatWeight(val) {
            let car = val.find(v => v.baggageType == "CHECK_IN");
            return car && car.weight;
        }
    },
    mounted() {
        this.loadServerDate();
        this.lampFun();
        EventBus.$on("hasFlightNotice", flag => {
            this.hasNotice = flag;
        });
        const { deptAirportCode, arrAirportCode } = this.$route.query;
        if(!deptAirportCode || !arrAirportCode) {
            this.getDicConfigByKey('INDEX_DEFAULT_OD')
        }
    },
    methods: {
        initMounted() {
            this.loadHotAirlines();
            this.restaurants = this.getAirports();
            this.today = moment(this.serverDate, "YYYY-MM-DD");
            var historyFilter = Vue.ls.get(SEARCH_FILTER);
            this.searchfilter.deptAirportCode = this.$route.query.deptAirportCode;
            // 判断军警残
            if (this.$route.query && this.$route.query.normalBooking == false) {
                this.$alert(
                    "<div><strong>残疾军人/伤残人民警察优惠客票购票、乘机须知：</strong>" +
                        "<div>1.残疾军人/伤残人民警察购买优惠客票（以下简称军/警残客票），须在购票时提供有效身份证件，以及由国家统一制发的、与乘机人有效身份证件相符的有效残疾军人（警察）证件信息。</div><div>2.军/警残客票每张订单仅限一位乘机人预订。</div><div>3.军/警残客票无法办理线上值机和自助值机，需预留充足的办理时间，于乘机当日在机场办理乘机手续，同时提供有效身份证件以及残疾军人（警察）证件原件，确保与购票时提供的信息保持一致。如经查验证件无效或证件不一致的旅客，湖南航空有权拒绝其登机。</div><div>4.购票者具有提供真实信息的法律义务，伪造、冒充军人、警察身份属于严重违法行为，需承担法律责任。</div><div>5.军/警残客票暂不支持线上购票/自愿变更/退票，请拨打湖南航空客服热线<span style='color:#d70039'>4008337777</span>为您服务</div></div>",
                    {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: "我知道了",
                        customClass: "message_box_junjin"
                    }
                );
            }
            if (this.searchfilter.deptAirportCode === undefined && historyFilter != null) {
                this.searchfilter.deptAirportCode = historyFilter.deptAirportCode;
            }

            if (this.searchfilter.deptAirportCode === undefined && historyFilter == null) {
                this.searchfilter.deptAirportCode = "CSX";
            }

            var airpotsItem = this.getAirport(this.searchfilter.deptAirportCode);
            if (airpotsItem !== undefined) {
                this.searchfilter.deptAirport = airpotsItem.cityName;
            }

            this.searchfilter.arrAirportCode = this.$route.query.arrAirportCode;
            if (this.searchfilter.arrAirportCode === undefined && historyFilter != null) {
                this.searchfilter.arrAirportCode = historyFilter.arrAirportCode;
            }

            if (this.searchfilter.arrAirportCode === undefined && historyFilter == null) {
                this.searchfilter.arrAirportCode = "WUX";
            }

            airpotsItem = this.getAirport(this.searchfilter.arrAirportCode);
            if (airpotsItem !== undefined) {
                this.searchfilter.arrAirport = airpotsItem.cityName;
            }
            this.searchfilter.deptDate = this.$route.query.deptDate;
            if (this.searchfilter.deptDate === undefined && historyFilter != null) {
                this.searchfilter.deptDate = historyFilter.deptDate;
            }
            if (this.searchfilter.deptDate === undefined && historyFilter == null) {
                this.searchfilter.deptDate = moment()
                    .add(1, "days")
                    .format("YYYY-MM-DD");
            }
            // 往返
            if (this.$route.query.switch) {
                this.searchfilter.activeSwitch = this.$route.query.switch;
            }

            if (this.searchfilter.activeSwitch == "RT") {
                this.switchTrip(this.searchfilter.activeSwitch);
                this.searchfilter.retDate = this.$route.query.retDate;
                if (this.searchfilter.retDate === undefined && historyFilter != null) {
                    this.searchfilter.retDate = historyFilter.retDate;
                }
            }

            this.initPriceBar();
            setTimeout(() => {
                if (this.priceCalender != null) {
                    this.priceCalender.destroy(false);
                }
                this.priceCalender = new Swiper(
                    ".swiper-container-lowprice",
                    {
                        slidesPerView: 7,
                        allowTouchMove: false,
                        observer: true,
                        observeParents: true,
                        autoplay: false
                    },
                );

                if (this.$route.query.deptAirportCode !== undefined) {
                    this.searchFlight();
                }
                console.log("初使化完成");
            }, 200);

            // 加载通告：
            this.showNotices();
        },
        initPriceBar() {
            this.dateArr.slice(0, this.dateArr.length);
            this.priceClass.slice(0, this.priceClass.length);
            for (let i = 0; i < 365; i++) {
                this.dateArr.push({
                    date: moment(this.serverDate, "YYYY-MM-DD")
                        .add(i, "days")
                        .format("MM月DD日 ddd"),
                    price: "--",
                    deptDate: moment(this.serverDate, "YYYY-MM-DD")
                        .add(i, "days")
                        .format("YYYY-MM-DD")
                });

                this.priceClass.push({ classType: "date-item" });
            }
        },
        loadServerDate() {
            var that = this;

            synServerDate().then(
                res => {
                    if (res.success) {
                        that.serverDate = res.data;
                        that.initMounted();
                    }
                },
                fail => {
                    console.log("syndate fail");
                    that.serverDate = moment().format("YYYY-MM-DD");
                    that.initMounted();
                }
            );
        },
        loadHotAirlines() {
            var req = {};
            var that = this;
            getHotAirlines(req).then(res => {
                if (res.success) {
                    that.hotAirports = res.data.hotAirports;
                    that.hotAirlines = res.data.hotAirlines;
                    if (that.initVisible && that.hotAirports.length > 0) {
                        that.activeHotAirport = that.hotAirports[0];
                        this.loadLowestFlights(this.activeHotAirport);
                    }
                }
            });
        },
        loadLowestFlights(airportItem) {
            var req = { t: this.hotAirlines[airportItem.airportCode] };
            getLowestFlights(req).then(res => {
                if (res.success) {
                    this.lowestFlightsData = res.data;
                }
            });
        },
        changeFlightsAirports() {
            var chineseName = this.searchfilter.deptAirport;
            var airportCode = this.searchfilter.deptAirportCode;
            this.searchfilter.deptAirport = this.searchfilter.arrAirport;
            this.searchfilter.deptAirportCode = this.searchfilter.arrAirportCode;
            this.searchfilter.arrAirport = chineseName;
            this.searchfilter.arrAirportCode = airportCode;
        },
        async itemClick(index, searchFlag) {
            // console.log(this.priceClass);
            if (this.searching) return;
            this.searching = 1;
            for (var i = 0; i < this.priceClass.length; i++) {
                if (this.priceClass[i].classType !== "date-item") {
                    this.priceClass[i].classType = "date-item";
                }
            }

            this.priceClass[index].classType = "date-item active-item";
            if (searchFlag) {
                // 搜索去程 || 更换查询条件 清空去程选择航班
                var temp = this.searchfilter.deptAirportCode + this.searchfilter.arrAirportCode;
                if (this.activeJouryType == "GO" || temp != this.activeSearchRoute) {
                    this.activeSearchRoute = temp;
                    this.searchfilter.deptDate = this.dateArr[index].deptDate;
                    this.selectFlight.singleFlight = null;
                    this.selectFlight.goFlight = null;
                    await this.searchFlight();
                } else if (this.activeJouryType == "BACK") {
                    this.searchfilter.retDate = this.dateArr[index].deptDate;
                    await this.searchBackFlight();
                }
            }

            this.priceCalender.slideTo(index - 3 >= 0 ? index - 3 : 0, 100, false);
            this.searching = 0;
        },
        // 滚动日期向前
        /**
         * @param type 舱类型
         */
        // FIXME type-1、2、3、4 请修改为对应的类型
        chooseCabin(type) {
            // 取消选择舱类型
            if (this.cabinType === type) this.cabinType = "";
            this.cabinType = type;
        },
        chooseCurrentCabin(type, flightItem, currentCabin) {
            // 取消选择舱类型
            if (this.cabinType === type) this.cabinType = "";
            this.cabinType = type;
            //console.log(currentCabin);
            flightItem.currentCabin = currentCabin;
            flightItem.showFlightUnit = true;
        },
        // 滚动日期后退，关于日期联动的数据在此操作
        goPrev() {
            this.priceCalender.slidePrev();
        },
        // 滚动日期向前，关于日期联动的数据在此操作
        goNext() {
            this.priceCalender.slideNext();
        },
        switchTrip(type) {
            if (type === "OW") {
                // TODO 这里需要把返回日期清除
            } else {
                this.searchfilter.retDate = moment(this.searchfilter.deptDate)
                    .add(7, "days")
                    .format("YYYY-MM-DD");
            }
            this.activeSwitch = type;
        },
        handleDateFocus() {
            // TODO 这里可以做date-picker的逻辑
            this.closeFilter();
        },
        handleFromFocus() {
            this.toFilterShow = false;
            this.fromFilterShow = true;
        },
        handleToFocus() {
            this.fromFilterShow = false;
            this.toFilterShow = true;
        },
        selectDeptAirportHandler(airport) {},
        selectArrAirportHandler(airport) {},
        closeFilter() {
            this.fromFilterShow = false;
            this.toFilterShow = false;
        },
        getAirports: function() {
            return fetchHotAirports();
        },
        getAirport: function(code) {
            var airpots = this.getAirports();
            for (var i = 0; airpots && i < airpots.length; i++) {
                if (airpots[i].airportCode === code) {
                    return airpots[i];
                }
            }
        },
        handleSearchOnblur(target, pannel, type, event) {
            var that = this;
            var filterResult = [];
            if (type == "dept") {
                if (target.deptAirport) {
                    filterResult = this.restaurants.filter(this.createFilter(target.deptAirport));
                }
            } else {
                if (target.arrAirport) {
                    filterResult = this.restaurants.filter(this.createFilter(target.arrAirport));
                }
            }

            if (filterResult && filterResult.length > 0) {
                this.defaultAirport = filterResult[0];
            } else {
                that.defaultAirport = {};
            }
            console.log(that.defaultAirport);
            if (type == "dept") {
                target.deptAirport = that.defaultAirport.cityName ? that.defaultAirport.cityName : "";
                target.deptAirportCode = that.defaultAirport.airportCode ? that.defaultAirport.airportCode : "";
                if (event != null && target.deptAirport) {
                    pannel.close();
                }
            } else {
                target.arrAirport = that.defaultAirport.cityName ? that.defaultAirport.cityName : "";
                target.arrAirportCode = that.defaultAirport.airportCode ? that.defaultAirport.airportCode : "";
                if (event != null && target.arrAirport) {
                    //
                    //
                    pannel.close();
                }
            }
        },
        querySearch(queryString, cb) {
            if (!queryString || queryString.length <= 0) {
                cb([]);
                this.$refs.deptAirportPannel.show();
                this.$refs.arrAirportPannel.close();
                this.defaultAirport = {};
                this.handleSearchOnblur(this.searchfilter, this.$refs.deptAirportPannel, "dept", null);
                return;
            }
            this.$refs.deptAirportPannel.close();
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            if (results != null && results.length > 0) {
                this.defaultAirport = results[0];
            }
            cb(results);
        },
        querySearch2(queryString, cb) {
            if (!queryString || queryString.length <= 0) {
                cb([]);
                this.$refs.arrAirportPannel.show();
                this.$refs.deptAirportPannel.close();
                this.defaultAirport = {};
                this.handleSearchOnblur(this.searchfilter, this.$refs.arrAirportPannel, "arr", null);
                return;
            }
            this.$refs.arrAirportPannel.close();
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            if (results != null && results.length > 0) {
                this.defaultAirport = results[0];
            }
            cb(results);
        },
        createFilter(queryString) {
            return restaurant => {
                return (
                    restaurant.chineseName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0 ||
                    restaurant.cityName.toLowerCase().indexOf(queryString.toLowerCase()) >= 0 ||
                    (restaurant.pinYin && restaurant.pinYin.toLowerCase().indexOf(queryString.toLowerCase()) >= 0) ||
                    restaurant.airportCode.toLowerCase().indexOf(queryString.toLowerCase()) >= 0
                );
            };
        },
        handleSelect(item) {
            this.searchfilter.deptAirport = item.cityName;
            this.searchfilter.deptAirportCode = item.airportCode;
            this.defaultAirport = item;
        },
        handleSelect2(item) {
            this.searchfilter.arrAirport = item.cityName;
            this.searchfilter.arrAirportCode = item.airportCode;
            this.defaultAirport = item;
        },
        selectOWFlight(item, product) {
            if (this.checkLogin()) {
                var needCheckVerifyStatus = false;
                if (product.productSpace.product && product.productSpace.product.validatorsEnumList) {
                    product.productSpace.product.validatorsEnumList.forEach(item => {
                        if (item == "MEMBER_VERIFY_VALIDATOR") {
                            needCheckVerifyStatus = true;
                        }
                    });
                }
                if (!this.checkVerifyStatus() && needCheckVerifyStatus) {
                    this.$message({
                        showClose: true,
                        message: "很抱歉、您还没完成实名认证、可登录“湖南航空”小程序中会员中心-实名认证中完成认证",
                        type: "error"
                    });

                    return;
                }
                this.selectFlight.jouryType = this.activeSearchJouryType;

                if (product == null || product == undefined) {
                    this.selectFlight.owFlight = item.currentCabin;
                } else {
                    this.selectFlight.owFlight = product;
                }
                Vue.ls.set(SELECT_FLIGHT, this.selectFlight);
                this.$router.push({
                    path: "/passenger",
                    query: { t: new Date().getTime() }
                });
            }
        },
        selectGoFlight(item, product) {
            // 触发返程查询
            var needCheckVerifyStatus = false;
            if (product.productSpace.product && product.productSpace.product.validatorsEnumList) {
                product.productSpace.product.validatorsEnumList.forEach(item => {
                    if (item == "MEMBER_VERIFY_VALIDATOR") {
                        needCheckVerifyStatus = true;
                    }
                });
            }
            if (!this.checkVerifyStatus() && needCheckVerifyStatus) {
                this.$message({
                    showClose: true,
                    message: "您还未通过实名认证，请先实名认证后在进行购买",
                    type: "error"
                });

                return;
            }
            this.selectFlight.singleFlight = item;
            this.selectFlight.jouryType = this.activeSearchJouryType;
            if (product == null || product == undefined) {
                this.selectFlight.goFlight = item.currentCabin;
            } else {
                this.selectFlight.goFlight = product;
            }

            this.searchBackFlight();
        },
        selectBackFlight(item, product) {
            if (this.checkLogin()) {
                var needCheckVerifyStatus = false;
                if (product.productSpace.product && product.productSpace.product.validatorsEnumList) {
                    product.productSpace.product.validatorsEnumList.forEach(item => {
                        if (item == "MEMBER_VERIFY_VALIDATOR") {
                            needCheckVerifyStatus = true;
                        }
                    });
                }

                if (!this.checkVerifyStatus() && needCheckVerifyStatus) {
                    this.$message({
                        showClose: true,
                        message: "很抱歉、您还没完成实名认证、可登录“湖南航空”小程序中会员中心-实名认证中完成认证",
                        type: "error"
                    });

                    return;
                }
                if (product == null || product == undefined) {
                    item.currentCabin.segmentList[0].segmentType = "BACK";
                    this.selectFlight.backFlight = item.currentCabin;
                } else {
                    product.segmentList[0].segmentType = "BACK";
                    this.selectFlight.backFlight = product;
                }

                Vue.ls.set(SELECT_FLIGHT, this.selectFlight);
                this.$router.push({
                    path: "/passenger",
                    query: { t: new Date().getTime() }
                });
            }
        },
        checkVerifyStatus() {
            var currentUser = Vue.ls.get(CURRENT_USER);
            if (currentUser && currentUser.verifyStatus && currentUser.verifyStatus == "CERTIFIED_SUCCESS") {
                return true;
            }

            return false;
        },
        checkLogin() {
            var currentUser = Vue.ls.get(CURRENT_USER);
            if (currentUser == null || currentUser.phone === undefined || currentUser.phone.length <= 0) {
                this.$confirm("你还没有登录，请先登录后在进行预订", "提示", {
                    confirmButtonText: "立即登录",
                    cancelButtonText: "取消",
                    type: "warning",
                    customClass: "m-message-box"
                })
                    .then(() => {
                        Vue.ls.set("URL_REDIRECT", window.location.href);
                        this.$router.push({
                            path: "/login",
                            query: { t: new Date().getTime() }
                        });
                    })
                    .catch(() => {
                        return false;
                    });

                return false;
            }

            return true;
        },
        getDiffDate(targetDate) {
            let date1 = new Date();
            if (this.serverDate) {
                date1 = new Date(this.serverDate);
            }
            let date2 = new Date(targetDate);
            date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
            date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
            const diff = date2.getTime() - date1.getTime();
            const diffDate = diff / (24 * 60 * 60 * 1000);
            return diffDate;
        },
        resetLowestBar() {
            for (var lowestIndex = 0; lowestIndex < 365; lowestIndex++) {
                this.dateArr[lowestIndex].price = "--";
            }
        },
        loadLowestFlight(request) {
            getLowestSchedule(request).then(res => {
                if (res.success) {
                    var tempData = res.data;
                    if (res.data.length == 0) {
                        this.dateArr.forEach(item => {
                            item.price = "--";
                        });
                    } else {
                        for (var lowestIndex = 0; lowestIndex < tempData.length; lowestIndex++) {
                            var diffIndex = this.getDiffDate(tempData[lowestIndex].flightDate);
                            this.dateArr[diffIndex].price = Math.floor(tempData[lowestIndex].amount);
                        }
                    }
                } else {
                    this.flights = [];
                    this.noResultNotice = res.errorMessage;
                }
            });
        },
        async searchBackFlight() {
            this.tripDesc = "往返：返程(" + this.searchfilter.arrAirport + "-" + this.searchfilter.deptAirport + ")";

            var temp = this.searchfilter.deptAirportCode + this.searchfilter.arrAirportCode;
            if (temp != this.activeSearchRoute) {
                this.activeSearchRoute = temp;
                this.selectFlight.singleFlight = null;
                this.selectFlight.goFlight = null;
                this.searchFlight();
                return;
            }

            if (
                this.searchfilter.deptAirportCode === "" ||
                this.searchfilter.arrAirportCode === "" ||
                this.searchfilter.deptDate === "" ||
                this.searchfilter.deptAirportCode === undefined ||
                this.searchfilter.arrAirportCode === undefined ||
                this.searchfilter.deptDate === undefined
            ) {
                return;
            }
            var diffDate = this.getDiffDate(this.searchfilter.retDate);
            this.itemClick(diffDate, false);
            var request = {
                t: {
                    tripType: "OW",
                    depAirportCode: this.searchfilter.arrAirportCode,
                    arrAirportCode: this.searchfilter.deptAirportCode,
                    depDate: this.searchfilter.retDate
                }
            };
            this.searchLoadingStatus = true;
            this.loadLowestFlight(request);
            this.activeJouryType = "BACK";
            var that = this;
            return getFlightList(request).then(res => {
                if (res.success) {
                    var tempData = res.data;

                    var filterResult = [];
                    for (var i = 0; i < tempData.length; i++) {
                        var flight = tempData[i];
                        flight.currentCabin = flight.flightUnitVOList[0];
                        if (tempData.length <= 1) {
                            flight.showFlightUnit = true;
                        } else {
                            flight.showFlightUnit = false;
                        }
                        // groupBy cabinGroupName
                        let cabinTypes = [];
                        flight.ALL = [[], [], []];
                        flight.flightUnitVOList.map(v => {
                            if (cabinTypes.indexOf(v.cabinGroupName) > -1) {
                                flight.ALL[cabinTypes.indexOf(v.cabinGroupName)].push(v);
                            } else {
                                cabinTypes.push(v.cabinGroupName);
                                flight.ALL[cabinTypes.indexOf(v.cabinGroupName) || 0] = [];
                                flight.ALL[cabinTypes.indexOf(v.cabinGroupName) || 0].push(v);
                            }
                        });
                        flight.ALL = flight.ALL.sort((star, next) => {
                            if (star == [] || star.length == 0) {
                                return 1;
                            }
                            if (next == [] || star.length == 0) {
                                return -1;
                            }
                            // 按仓等的指定顺序排序
                            var order = ["F", "C", "G", "Y"];
                            return order.indexOf(star[0].segmentList[0].cabin.cabinClass) - order.indexOf(next[0].segmentList[0].cabin.cabinClass);
                        });
                        var currentFlightLowestIndex = this.getDiffDate(flight.depDatetime);
                        this.dateArr[currentFlightLowestIndex].price = flight.currentCabin.adultTotalPrice.amount;

                        if (that.selectFlight.goFlight.segmentList[0].arrDatetime + 2 * 60 * 60 * 1000 < flight.flightUnitVOList[0].segmentList[0].depDatetime) {
                            filterResult.push(flight);
                        }
                    }
                    filterResult.forEach(item => {
                        item._id = new Date().getTime() + "-" + Math.random();
                    });
                    this.flights = filterResult;
                    if (this.flights.length == 0) {
                        this.noResultNotice == "未查询到满足匹配的航班组合";
                        this.searchLoadingStatus = false;
                    }
                    this.$nextTick(function() {
                        //console.log("渲染完成");
                        this.searchLoadingStatus = false;
                    });
                } else {
                    this.flights = [];
                    this.noResultNotice = res.errorMessage;
                    this.searchLoadingStatus = false;
                }
            });
        },
        async searchFlight() {
            this.initVisible = false;
            if (this.activeSwitch !== "OW") {
                // 判断时期是否合法
                if (this.searchfilter.retDate === "" || this.searchfilter.retDate === undefined) {
                    this.$message({
                        showClose: true,
                        message: "请选择返程日期",
                        type: "error"
                    });
                    this.$refs.retDatePicker.focus();
                    return;
                }

                // 返程日期必须在去程日期之后
                let dateDept = new Date(this.searchfilter.deptDate);
                let dateRet = new Date(this.searchfilter.retDate);
                if (dateRet.getTime() < dateDept.getTime() && this.searchfilter.deptDate != moment(this.searchfilter.retDate).format("YYYY-MM-DD")) {
                    this.$message({
                        showClose: true,
                        message: "返程日期必须在去程日期之后",
                        type: "error"
                    });
                    this.$refs.retDatePicker.focus();
                    return;
                }
            }

            this.selectFlight.singleFlight = null;
            this.selectFlight.goFlight = null;
            if (
                this.searchfilter.deptAirportCode === "" ||
                this.searchfilter.arrAirportCode === "" ||
                this.searchfilter.deptDate === "" ||
                this.searchfilter.deptAirportCode === undefined ||
                this.searchfilter.arrAirportCode === undefined ||
                this.searchfilter.deptDate === undefined
            ) {
                return;
            }

            Vue.ls.set(SEARCH_FILTER, this.searchfilter);
            var diffDate = this.getDiffDate(this.searchfilter.deptDate);
            this.activeSearchRoute = this.searchfilter.deptAirportCode + this.searchfilter.arrAirportCode;
            this.itemClick(diffDate, false);
            var request = {
                t: {
                    tripType: "OW",
                    depAirportCode: this.searchfilter.deptAirportCode,
                    arrAirportCode: this.searchfilter.arrAirportCode,
                    depDate: this.searchfilter.deptDate
                }
            };
            this.searchLoadingStatus = true;
            this.loadLowestFlight(request);
            this.activeSearchJouryType = this.activeSwitch;
            this.activeJouryType = "GO";
            if (this.activeSearchJouryType === "OW") {
                // 单程：
                this.tripDesc = "" + this.searchfilter.deptAirport + "-" + this.searchfilter.arrAirport + "";
            } else {
                //往返：去程()
                this.tripDesc = this.searchfilter.deptAirport + "-" + this.searchfilter.arrAirport;
            }
            return getFlightList(request).then(res => {
                if (res.success) {
                    var tempData = res.data;
                    var minPrice = 0;
                    for (var i = 0; i < tempData.length; i++) {
                        var flight = tempData[i];
                        flight.currentCabin = flight.flightUnitVOList[0];
                        if (tempData.length <= 1) {
                            flight.showFlightUnit = true;
                        } else {
                            flight.showFlightUnit = false;
                        }
                        // groupBy cabinGroupName
                        let cabinTypes = [];
                        flight.ALL = [[], [], []];
                        flight.flightUnitVOList.map(v => {
                            if (cabinTypes.indexOf(v.cabinGroupName) > -1) {
                                flight.ALL[cabinTypes.indexOf(v.cabinGroupName)].push(v);
                            } else {
                                cabinTypes.push(v.cabinGroupName);
                                flight.ALL[cabinTypes.indexOf(v.cabinGroupName) || 0] = [];
                                flight.ALL[cabinTypes.indexOf(v.cabinGroupName) || 0].push(v);
                            }
                        });
                        flight.ALL = flight.ALL.sort((star, next) => {
                            // 按仓等的指定顺序排序
                            var order = ["F", "C", "G", "Y"];
                            if (star == [] || star.length == 0) {
                                return 1;
                            }
                            if (next == [] || star.length == 0) {
                                return -1;
                            }
                            return order.indexOf(star[0].segmentList[0].cabin.cabinClass) - order.indexOf(next[0].segmentList[0].cabin.cabinClass);
                        });

                        if (tempData.length > 1) {
                            this.showFlightUnit = false;
                        }

                        flight.flightUnitVOList.forEach(item => {
                            if (minPrice == 0 || minPrice > item.adultTotalPrice.amount) {
                                minPrice = item.adultTotalPrice.amount;
                            }
                        });
                        var currentFlightLowestIndex = this.getDiffDate(flight.depDatetime);
                        this.dateArr[currentFlightLowestIndex].price = minPrice;
                    }
                    if (tempData.length == 0) {
                        let currentSearchIndex = this.getDiffDate(this.searchfilter.deptDate);
                        this.dateArr[currentSearchIndex].price = "--";
                    }

                    this.flights = tempData;
                    this.$nextTick(function() {
                        this.searchLoadingStatus = false;
                    });
                } else {
                    this.flights = [];
                    this.noResultNotice = res.errorMessage;
                    this.searchLoadingStatus = false;
                    var currentSearchIndex = this.getDiffDate(this.searchfilter.deptDate);
                    this.dateArr[currentSearchIndex].price = "--";
                }
            });
        },
        groupByCabinClassName(cabinClassName, flightUnitVOList) {
            let unitList = [];
            for (let unit of flightUnitVOList) {
                if (unit.cabinGroupName === cabinClassName) {
                    unitList.push(unit);
                }
            }
            return unitList;
        },
        groupByCabinClassCode(cabinCodeGroup, flightUnitVOList) {
            let unitList = [];
            for (let unit of flightUnitVOList) {
                if (unit.cabinCodeGroup === cabinCodeGroup) {
                    unit.cabinGroupName = "舒适经济舱";
                    unitList.push(unit);
                }
            }
            return unitList;
        },
        changeHot(item) {
            this.activeHotAirport = item;
            this.loadLowestFlights(this.activeHotAirport);
        },
        gotoHotline: function(hotItem) {
            this.searchfilter.deptAirportCode = hotItem.depAirportCode;
            this.searchfilter.arrAirportCode = hotItem.arrAirportCode;
            this.searchfilter.deptDate = hotItem.flightDate;
            this.searchfilter.deptAirport = hotItem.depAirportName;
            this.searchfilter.arrAirport = hotItem.arrAirportName;
            this.searchFlight();
        },
        lampFun() {
            let lampX = 0;
            let width = 1200;
            var inner = this.innovate;
            let interval = setInterval(() => {
                if (Math.abs(this.lampX) >= width / 7) {
                    this.lampX = 0;
                }
                this.lampX--;
            }, 60);
        },
        // 通告弹框
        showNotices() {
            let flights = [
                {
                    arrAirportCode: this.searchfilter.arrAirportCode,
                    depAirportCode: this.searchfilter.deptAirportCode
                }
            ];
            if (this.activeSwitch === "RT") {
                flights.push({
                    arrAirportCode: this.searchfilter.deptAirportCode,
                    depAirportCode: this.searchfilter.arrAirportCode
                });
            }
            let msg = {
                noticePage: "BOOK1",
                flights: flights
            };
            EventBus.$emit("showNotice", msg);
        },
        // 获取配置的默认OD
        async getDicConfigByKey(key) {
            try {
                const res = await queryDict({key});
                if (res && res.success) {
                    const result = res.data ? JSON.parse(res.data) : null;
                    const obj = {
                        deptAirport: result?.dept?.cityName || '长沙',
                        deptAirportCode: result?.dept?.airportCode || 'CSX',
                        arrAirport: result?.arr?.cityName || '昆明',
                        arrAirportCode: result?.arr?.airportCode || 'KMG',
                    }
                    this.searchfilter = {...this.searchfilter, ...obj}
                }
            } catch (e) {
                console.log(e);
            }
        },
    }
};
</script>
<style>
.el-button--primary.is-active,
.el-button--primary:active {
    background: #d44040;
    border-color: #d44040;
    color: #fff;
}

.el-button--primary:focus,
.el-button--primary:hover {
    background: #d44040;
    border-color: #d44040;
    color: #fff;
}
</style>
<style scoped lang="less">
.date-item {
    cursor: pointer;
}

.iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bolder;
    color: #e34c4c;
}

.cabin-type li {
    padding: 16px 0;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    color: #4d4e4e;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 160px;
    background: #ffffff;
    width: 20%;
}

.cabin-type {
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    border-left: 1px solid #ededed;
    display: flex;
}

.cabin-name {
    width: 12%;
    font-weight: 700;
    /* color: #ab2e2e !important; */
}

.cabin-discount {
    width: 10%;
    font-size: 24px;

    justify-content: flex-end !important;
}

.cabin-rule {
    width: 18%;
}

.cabin-rule span {
    display: inline-block;
    font-size: 14px;
    // font-weight: 700;
    color: #aaaaaa;
}

.cabin-rule span:first-child {
    margin-right: 12px;
}

.cabin-tag {
    width: 20%;
}

.cabin-tag span {
    display: inline-block;
    margin: 3px 5px;
    padding: 4px 6px;
    font-size: 12px;
    color: #fff;
    background-color: #ff7b00;
}

.cabin-preferential {
    width: 14%;
    font-size: 14px !important;
    color: #d70039 !important;
}

.cabin-price {
    width: 16%;
}

.cabin-price {
    justify-content: flex-end !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #d70039 !important;
}

.cabin-action {
    width: 10%;
}

.cabin-action a {
    display: inline-block;
    width: 70px;
    height: 50px;
    border: 1px solid #d70039;
    position: absolute;
    right: 20px;
}

.cabin-action a span:first-child {
    display: block;
    width: 100%;
    height: 28px;
    font-size: 14px;
    font-weight: 700;
    line-height: 28px;
    color: #fff;
    text-align: center;
    background-color: #d70039;
}

.cabin-action a span:last-child {
    display: block;
    width: 100%;
    height: 22px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #ab2e2e;
    text-align: center;
}

.active-cabin {
    background-color: #d70039;
    border-left-color: #d31a1a;
    color: #fff !important;
}
.first-cabin {
    background-color: #d3b798;
    border-left-color: #d3b798;
}

.choose-cabin {
    font-weight: normal !important;
    margin-top: -30px !important;
    height: 160px !important;
    line-height: 65px !important;
    /* color: #ab2e2e; */
    cursor: pointer;
    position: relative;
    /* border-left: 1px solid #eeeeee; */
}
.active-cabin::after {
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 160px;
    right: 0;
    border-width: 30px 55px 0;
    border-style: solid;
    border-color: #d70039 transparent transparent;
}
.first-cabin::after {
    border-color: #d3b798 transparent transparent;
}

.choose-cabin i {
    font-weight: 700;
}

.icon-Check {
    font-size: 18px;
    color: #ffffff;
}

.cabin-group {
    background-color: #f8f8f8;
    /* margin-top: 14px; */
}

.flight-main-item p:first-child {
    font-size: 16px;
    /* font-weight: 700; */
    height: 22px;
    line-height: 22px;
    margin-top: 12px;
}

.flight-main-item p:nth-child(2) {
    height: 20px;
    line-height: 20px;
    margin-top: 24px;
}

.flight-main-item p:nth-child(3) {
    height: 17px;
    font-size: 12px;
    line-height: 17px;
    margin-top: 12px;
}
.during {
    position: relative;
    &-air {
        margin-top: -10px;
        width: 32px;
        height: 32px;
    }
    &-line {
        width: 100%;
        height: 1px;
        background: #7f7e7c;
        display: block;
        margin-bottom: -6px;
    }
}
.during p:first-child {
    margin-top: 18px;
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    font-weight: normal;
}

.during p:nth-child(2) {
    line-height: 18px;
}

.flight-main {
    width: 1198px;
    height: 160px;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 2px;
    box-shadow: 0 10px 10px 0 #cccccc;
    display: flex;
    position: absolute;
    z-index: 1000;
    position: relative;
}

.flight-main-item {
    display: block;
    width: 150px;
    height: 85px;
    font-size: 14px;
    font-weight: 400;
    line-height: 85px;
    color: #4d4e4e;
    text-align: center;
    margin-top: 30px;
}

.flight-item {
    position: relative;
    display: block;
    // margin: 16px 0;
    width: 100%;
    min-height: 300px;
    background: #ffffff;
}

.ticket-flight-header {
    margin-top: 25px;
    height: 44px;
    background-color: #f8f8f8;
    border: 1px solid #ededed;
    display: flex;
}

.ticket-flight-header li {
    width: 12.5%;
    height: 44px;
    line-height: 44px;
    text-align: center;
}

.date-info {
    // margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
}

.price-info {
    line-height: 2.4;
    font-weight: 400;
    text-align: center;
    span {
        font-size: 25px !important;
    }
}

.calender-swiper {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
}

.swiper-slide {
    box-sizing: border-box;
    /* padding: 0 2.5px; */
    // width: 100px !important;
}

.date-item {
    /* border: 1px solid #eae7e7; */
    border-left: none;
    border-bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
}

.active-item {
    border: 1px solid #d70039;
    background-color: #d70039;
    color: #ffffff;
    border-bottom: 0;

    /* height: 65px; */
}

.prev,
.next {
    width: 60px;
    height: 65px;
    line-height: 67px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    background-color: #ffffff;
    /* border: 1px solid #eae7e7; */
    border-bottom: none;
    cursor: pointer;
    color: #d70039;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
}

.swiper-container-lowprice {
    width: calc(100% - 54px);
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.calender-price {
    border: 1px solid #ededed;
    margin-top: 28px;
}

.calender-header {
    height: 41px;
}

.calender-header h3 {
    height: 41px;
    line-height: 41px;
    font-size: 16px;
    font-weight: 700;
    box-sizing: border-box;
    padding: 0 10px;
}

.search-btn {
    width: 80px;
    height: 35px;
    margin-top: 42px;
    margin-left: 32px;
    background-color: #ff0505;
    color: #ffffff;
    border: 0;
    font-size: 14px;
    font-weight: 700;
}

.filter + .filter {
    margin-left: 32px;
}

.separet {
    height: 14px;
    transform: rotate(90deg);
    margin: 0 9px;
    margin-top: 20px;
    cursor: pointer;
    color: #cb1d36;
}

.el-icon-close {
    cursor: pointer;
}

.ticket {
    margin-top: 27px;
}

.search-filter {
    box-shadow: 0 1px 5px 1px #eaeaea;
    /* display: flex; */
}

.switcher {
    width: 73px;
    background-color: #f8f8f8;
}

.switcher .active {
    background-color: #ffffff;
}

.switch-item {
    height: 46.5px;
    line-height: 46.5px;
    text-align: center;
    cursor: pointer;
}

.switch-item a {
    font-size: 14px;
    font-weight: 700;
}

.switch-panel {
    // width: calc(100% - 73px);
    box-sizing: border-box;
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 25px;
    display: flex;
    position: relative;
    .fliter-btn {
        position: absolute;
        right: 20px;
        border-radius: 5px !important;
    }
}

.filter {
    width: 200px;
}

.filter .el-input {
    margin-top: 10px;
}

.filter-title {
    margin-top: 15px;
    font-size: 12px;
    color: #7f7e7c;
    font-weight: 700;
}

.filter-input {
    position: relative;
}

.filter-panel {
    background-color: #ffffff;
    position: absolute;
    top: 68px;
    left: 0;
    width: 424px;
    padding: 8px 16px 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1001;
}

.filter-panel-title {
    color: #9d9d9d;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-switch {
    display: flex;
    margin-top: 4px;
    margin-bottom: 6px;
}

.filter-switch-item {
    border: 1px solid #f87474;
    font-size: 14px;
    color: #666666;
    padding: 0 16px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
}

.filter-switch-item:last-child {
    border-left: 0;
}

.active-filter {
    background-color: #d60039;
    color: #ffffff;
}

.city-area-tabs {
    display: flex;
}

.tab-item {
    border-bottom: 1px solid #d3d3d3;
    width: 65px;
    height: 23px;
    font-size: 14px;
    color: #333333;
    text-align: center;
    line-height: 23px;
    cursor: pointer;
}

.filter-city-list a {
    display: inline-block;
    margin: 3px 3px 3px 0;
    padding: 4px 6px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #4d4e4e;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
}

.filter-city-list a:hover {
    background-color: #d60039;
    color: #ffffff;
}

.panel-header {
    /* border-bottom: 2px solid #e9e8e8; */
    margin-top: 10px;
    width: 400px;
}

.panel-header .panel-tabs {
    display: block;
    width: 100%;
    height: 42px;
    margin: 0;
    padding: 0;
}

.panel-tabs li {
    width: 50%;
    position: relative;
    float: left;
    /* background-color: #EEEEEEFF; */
}

.panel-tabs li:first-child {
    border-radius: 10px 0px 0 0;
}

.panel-tabs li:last-child {
    /* border-radius: 0px 10px 0 0; */
}

.panel-tabs li a {
    position: relative;
    display: block;
    margin: auto;
    width: 80px;
    height: 42px;
    font-size: 14px;
    font-weight: 400;
    line-height: 42px;
    text-align: center;
    cursor: pointer;
}

.panel-tabs .clearfix:after {
    display: block;
    height: 0;
    content: "";
    clear: both;
    overflow: hidden;
}

.panel-tabs .active {
    background-color: #d6003a;
    color: #ffffff;
}

.panel-tabs .active a {
    color: #ffffff;
}
.dinner-item p {
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-data {
    text-align: center;
    span {
        color: #929a9f;
    }
}
.go-flight {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 20px;
    position: absolute;
    span {
        display: flex;
        height: 100%;
        width: 20px;
    }
}
.pop-btn {
    text-align: center;
    border-radius: 0 10px 10px 0;
    line-height: 30px;
    color: #ffffff;
    border: 0;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-title {
    padding: 5px;
    color: #333;
    font-weight: 500;
    font-size: 32px;
    text-align: left;
}
.sub-title {
    padding: 5px;
    color: #333;
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 20px 0;
    text-align: left;
}
.discount-city li {
    display: inline-block;
    width: 60px;
    margin-left: 7px;
    padding: 5px 5px 7px 5px;
    background: #fdfdfd;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    border-radius: 5px;
}

.discount-city li.active {
    background: #d60039;
    color: #ffffff;
    font-size: 14px;
}
.recommend {
    padding: 20px;
    background: #ffffff;
}
.commendlist ul li {
    float: left;
    background-color: #ffffff;
    position: relative;
}
.commendlist ul li:not(:first-child) {
    margin-left: calc(80px / 3);
}
.commendlist ul li:hover a {
    background-color: #f7f7f7;
}
.commendlist ul li:hover .commend-item-bottom {
    background-color: #f7f7f7;
}

.commendlist ul li a {
    width: 230px;
    height: 150px;
    display: block;
    padding: 25px 18px;
    color: #adadad;
    background: rgb(253 240 243);
    border-radius: 10px;
}
.commend-item-bottom {
    position: absolute;
    top: 200px;
    left: 0;
    width: 270px;
    height: 32px;
    background: rgb(253 240 243);
    transform: skewY(3deg) translateY(-15px);
    border-radius: 12px 0 12px 12px;
    z-index: 1;
}

.commendlist ul li a dl {
    margin-bottom: 10px;
    height: 27px;
    line-height: 33px;
}

.commendlist ul li a dd {
    min-width: 40px;
    max-width: 110px;
    float: left;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #333;
}

.commendlist ul li a dd.center {
    min-width: 33px;
    max-width: 33px;
    margin: 0 6px;
}

.commendlist ul li a dd {
    min-width: 40px;
    max-width: 110px;
    float: left;
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #333;
}

.commendlist ul li a em div {
    display: inline;
}

.commendlist ul li a em {
    /* position: absolute; */
    right: 20px;
    top: 40px;
    color: #333;
}

.commendlist ul li a em .e2 {
    font-size: 30px;
    color: #cb1d36;
}

.commendlist ul li a dd.center .flight-to {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 18px;
    margin-top: 5px;
    padding: 0;
    vertical-align: middle;
    background: url("../../assets/map-mark-right.png") no-repeat;
}
// 通告
.innovate {
    width: 1200px;
    height: 46px;
    background: rgba(255, 177, 66, 0.1);
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 0 20px;
    .top {
        height: 100%;
    }
    margin-top: 20px;
}
.top .tip {
    font-weight: bold;
    color: #fe8d18;
    line-height: 46px;
    font-size: 14px;
}
.top .ctn {
    color: #666666;
    line-height: 46px;
    font-size: 14px;
}
.right-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    transform: rotate(-180deg);
}
</style>
